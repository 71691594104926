<template>
  <div class="tariffs">
    <TariffsCard />
  </div>
</template>

<script>
import TariffsCard from '@/components/tariffs/TariffsCard';

export default {
  name: 'Tariffs',
  created() {
    this.$store.commit('toggleLoader', false);
  },
  components: { TariffsCard },
};
</script>

<style lang="scss">
.tariffs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
</style>
